import {
  configureStore,
  combineReducers,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";

import loginReducer from "../features/login/loginSlice";
import mapStateReducer from "../features/map/mapStateSlice";
import deviceListReducer from "../features/deviceList/deviceListSlice";
import positionsListReducer from "../features/deviceList/positionsSlice";
import devicePositionsListReducer from "../features/deviceList/devicePositionsSlice";
import deviceReportsListReducer from "../features/deviceList/deviceReportsSlice";
import commandsListReducer from "../features/deviceList/commandsListSlice";
import dialogStateReducer from "../features/dialogs/dialogStateSlice";
import deviceReducer from "../features/system/deviceSlice";
import geoCoderReducer from "../features/geocoding/geocodeSlice";

const combinedReducer = combineReducers({
  login: loginReducer,
  mapState: mapStateReducer,
  deviceList: deviceListReducer,
  device: deviceListReducer,
  positionsList: positionsListReducer,
  devicePositionsList: devicePositionsListReducer,
  deviceReportsList: deviceReportsListReducer,
  commandsList: commandsListReducer,
  dialogState: dialogStateReducer,
  deviceItem: deviceReducer,
  geoCoder: geoCoderReducer,
});

// const rootReducer = (state, action) => {
//   if (action.type === "login/logoutUser/fulfilled") {
//     state = undefined;
//   }
//   return combinedReducer(state, action);
// };

export default configureStore({
  reducer: combinedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
