//import * as React from "react";
import React, { memo } from "react";
import _ from "lodash";
import { decode } from "html-entities";
import { useSelector, useDispatch } from "react-redux";
import DeviceData from "../features/deviceData/DeviceData";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";

import MenuIcon from "@mui/icons-material/Menu";

import CloseIcon from "@mui/icons-material/Close";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import useAppBarHeight from "../features/common/customHooks/useAppBarHeight";
import MapHolder from "../features/map/MapHolder";
import DeviceList from "../features/deviceList/DeviceList";
import { useWindowDimension } from "../features/common/customHooks/useWindowDimentions";
import {
  selectSelectedId,
  selectSelectedIds,
  selectSelectedDevices,
} from "../features/deviceList/deviceListSlice";
import { selectPortalName } from "../features/login/loginSlice";
import {
  selectPosition,
  selectPositions,
} from "../features/deviceList/positionsSlice";

import {
  setDetailsDrawOpen,
  selectDetailsDrawOpen,
} from "../features/map/mapStateSlice";

import BottomAppBar from "./BottomAppBar";

import MenuOpenIcon from "@mui/icons-material/MenuOpen";

const drawerWidth = 280;

const MapPage = ({ props }) => {
  // const { window } = props;
  const portalName = useSelector(selectPortalName);

  const selectedId = useSelector(selectSelectedId);
  const selectedIds = useSelector(selectSelectedIds);
  const position = useSelector(selectPosition(selectedId));
  const devices = useSelector(selectSelectedDevices(selectedIds));
  const positions = useSelector(selectPositions(selectedIds));
  const detailsDrawOpen = useSelector(selectDetailsDrawOpen);

  // console.log("Ids", selectedIds);
  // console.log("devs", devices);

  const dispatch = useDispatch();

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("md"));
  const { height } = useWindowDimension();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleDesktopDrawerToggle = () => {
    dispatch(setDetailsDrawOpen(!detailsDrawOpen));
  };

  // const container =
  //   window !== undefined ? () => window.document.body : undefined;

  const appBarHeight = useAppBarHeight();

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          bgcolor: "primary.dark",
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
        }}
      >
        <Toolbar sx={{ width: "100%" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon sx={{ fontSize: 42 }} />
          </IconButton>
          <Typography variant="h4" noWrap component="div">
            {portalName ? decode(portalName) : "Trackers Global"}
          </Typography>
          <Box sx={{ marginLeft: "auto", pr: "2px" }}>
            {selectedIds.length === 1 || detailsDrawOpen ? (
              <IconButton
                sx={{
                  color: "#fff",
                  ":hover": {
                    bgcolor: "primary.light", // theme.palette.primary.main
                    color: "#fff",
                  },
                }}
                aria-label="open drawer"
                edge="end"
                onClick={handleDesktopDrawerToggle}
              >
                {detailsDrawOpen ? (
                  <CloseIcon
                    sx={{
                      fontSize: 42,
                    }}
                  />
                ) : (
                  <MenuOpenIcon
                    sx={{
                      fontSize: 42,
                    }}
                  />
                )}
              </IconButton>
            ) : (
              <></>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <div component="nav" aria-label="mailbox folders">
        {isMobile ? (
          //MOBILE DRAW
          <Drawer
            // container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            <DeviceList
              appBarHeight={appBarHeight}
              controlHeight={height - appBarHeight * 2}
              drawerWidth={drawerWidth}
              onClose={handleDrawerToggle}
            />
          </Drawer>
        ) : (
          //DESKTOP DRAW
          <Drawer
            variant="permanent"
            sx={{
              width: drawerWidth,
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                borderRight: 0,
              },
            }}
            open
          >
            <DeviceList
              appBarHeight={appBarHeight}
              controlHeight={height - appBarHeight * 2}
              drawerWidth={drawerWidth}
            />
          </Drawer>
        )}

        <Drawer
          // container={container}
          variant="persistent"
          anchor="right"
          open={detailsDrawOpen}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              flexGrow: 1,
              p: 0,
              mt: `calc(${appBarHeight}px)`,
              height: `calc(100% - ${appBarHeight}px)`,
              width: {
                md: `calc(100% - ${drawerWidth}px)`,
                xs: `calc(100%)`,
              },
              borderRight: 0,
            },
          }}
        >
          <DeviceData selectedId={selectedId} />
        </Drawer>
      </div>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 0,
          height: `calc(100% - ${appBarHeight * 2}px)`,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <MapHolder
          className="map_wrap"
          selectedIds={selectedIds}
          selectedId={selectedId}
          position={position}
          devices={devices}
          positions={positions}
        ></MapHolder>

        <BottomAppBar drawerWidth={drawerWidth}></BottomAppBar>
      </Box>
    </Box>
  );
};

const areEqual = (prevProps, nextProps) => {
  if (_.isEqual(prevProps, nextProps)) {
    return true;
  }

  return false; // donot re-render
};
export default memo(MapPage, areEqual);
