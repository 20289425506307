import http from "../../services/httpService";
import config from "../../config.json";
import Qs from "qs";
import moment from "moment";

export async function loginUser(usercreds) {
  const response = await http.post(
    config.apiUrl + "/session",
    Qs.stringify({
      email: usercreds.email,
      password: usercreds.password,
    })
  );
  return response;
}

export async function getToken() {
  const dateObject = new Date();
  const expiry = moment(dateObject).add(7, "days").format();

  //console.log("expiry here", expiry);

  const response = await http.post(
    config.apiUrl + "/session/token",
    Qs.stringify({
      expiration: expiry,
    })
  );
  return response;
}

export async function loginWithToken(usertoken) {
  const response = await http.get(
    config.apiUrl + "/session?token=" + usertoken
  );
  return response;
}

export async function logoutUser(usertoken) {
  console.log(usertoken);
  const response = await http.delete(
    config.apiUrl + "/session?token=" + usertoken,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response;
}
