import React from "react";
import { styled } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import SearchIcon from "@mui/icons-material/Search";
import GradingIcon from "@mui/icons-material/Grading";
import { selectAllIds } from "../../features/deviceList/deviceListSlice";

import { useDispatch } from "react-redux";

const StyledIconButton = styled(IconButton)({
  height: "100%",
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 99,
  // Add additional CSS styles here
});

const SearchIconWrapper = () => {
  const dispatch = useDispatch();

  const handleClick = () => {
    // Handle button click event here
    dispatch(selectAllIds());
  };

  return (
    <StyledIconButton color="primary" onClick={handleClick}>
      <GradingIcon />
    </StyledIconButton>
  );
};

export default SearchIconWrapper;
