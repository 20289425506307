import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Utilization from "./Utilization";
import BatteryLevel from "./BatterLevel";
import JourneyHistory from "./JourneyHistory";
import StopsHistory from "./StopsHistory";
import AllData from "./AllData";
import DeviceAttributes from "./DeviceAttributes";
import { useSelector, useDispatch } from "react-redux";
import { selectSelectedId } from "../deviceList/deviceListSlice";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import Button from "@mui/material/Button";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { MobileDateRangePicker } from "@mui/x-date-pickers-pro/MobileDateRangePicker";
import { DesktopDateRangePicker } from "@mui/x-date-pickers-pro/DesktopDateRangePicker";
import TextField from "@mui/material/TextField";
import moment from "moment";

import {
  getPositionsAsync,
  clearPositions,
  clearPointOnMap,
  clearTraceOnMap,
  selectDevicePositions,
  selectDevicePositionsQueryState,
} from "../deviceList/devicePositionsSlice";
import {
  getTripsAsync,
  getStopsAsync,
  clearTrips,
  clearStops,
  selectDeviceTrips,
  selectDeviceStops,
  selectDeviceTripsQueryState,
  selectDeviceStopsQueryState,
} from "../deviceList/deviceReportsSlice";
import { useEffectAsync } from "../common/reactHelper";
import { useSumerizePower } from "../common/customHooks/useSummerizePower";
import { useSumerizeTrips } from "../common/customHooks/useSummerizeTrips";
import { useSumerizeStops } from "../common/customHooks/useSummerizeStops";
import DeviceDetails from "./DeviceDetails";
import { palette } from "@mui/system";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        height: 1,
        overflow: "auto",
        "& .MuiBox-root": {
          height: "100%",
          // p: 0,
          // m: 0,
        },
      }}
      className="tabpanel"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children} </Box>}
    </Box>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const DeviceData = (props) => {
  const selectedId = props.selectedId;

  const [SummarizedPower, setSummarizedPower] = useState([]);
  const [SummarizedTrips, setSummarizedTrips] = useState([]);
  const [SummarizedStops, setSummarizedStops] = useState([]);

  const SummarizePower = async (positions) => {
    const sumPower = await useSumerizePower(positions);
    setSummarizedPower(sumPower);
  };

  const SummarizeTrips = async (trips) => {
    const sumtrips = await useSumerizeTrips(trips);
    setSummarizedTrips(sumtrips);
  };

  const SummarizeStops = async (stops) => {
    const sumstops = await useSumerizeStops(stops);
    setSummarizedStops(sumstops);
  };

  const theme = useTheme();
  const dispatch = useDispatch();
  const firstDayOfLastWeek = moment().subtract(1, "weeks").startOf("week");
  const lastDayOfLastWeek = moment().subtract(1, "weeks").endOf("week");

  const [reportDate, setReportDate] = React.useState([
    firstDayOfLastWeek,
    lastDayOfLastWeek,
  ]);

  const handleGetData = () => {
    if (selectedId !== -1 && reportDate[0] && reportDate[1]) {
      getDevicePositions(selectedId, reportDate[0], reportDate[1]);
      getDeviceTrips(selectedId, reportDate[0], reportDate[1]);
      getDeviceStops(selectedId, reportDate[0], reportDate[1]);
    }
  };

  useEffectAsync(async () => {
    resetPositions();
  }, [selectedId]);

  const resetPositions = async () => {
    dispatch(clearPositions());

    dispatch(clearTrips());
    dispatch(clearStops());
    dispatch(clearPointOnMap());
    dispatch(clearTraceOnMap());
    SummarizePower([]);
    SummarizeTrips([]);
    SummarizeStops([]);
  };

  const getDevicePositions = (selectedId, from, to) => {
    to = moment(to).add(1, "d");
    to = moment(to).subtract(1, "s");

    if (selectedId !== -1) {
      const isoFrom = from.toISOString();
      let isoTo = to.toISOString();
      dispatch(getPositionsAsync([selectedId, isoFrom, isoTo]));
    }
  };
  const positions = useSelector(selectDevicePositions);
  const positionsQueryState = useSelector(selectDevicePositionsQueryState);

  const getDeviceTrips = (selectedId, from, to) => {
    to = moment(to).add(1, "d");
    to = moment(to).subtract(1, "s");

    if (selectedId !== -1) {
      const isoFrom = from.toISOString();
      let isoTo = to.toISOString();
      dispatch(getTripsAsync([selectedId, isoFrom, isoTo]));
    }
  };

  const getDeviceStops = (selectedId, from, to) => {
    to = moment(to).add(1, "d");
    to = moment(to).subtract(1, "s");

    if (selectedId !== -1) {
      const isoFrom = from.toISOString();
      let isoTo = to.toISOString();
      dispatch(getStopsAsync([selectedId, isoFrom, isoTo]));
    }
  };

  const trips = useSelector(selectDeviceTrips);
  const tripsQueryState = useSelector(selectDeviceTripsQueryState);

  const stops = useSelector(selectDeviceStops);
  const stopsQueryState = useSelector(selectDeviceStopsQueryState);

  useEffectAsync(async () => {
    if (positionsQueryState === "idle") {
      SummarizePower(positions);
    }
  }, [positionsQueryState]);

  useEffectAsync(async () => {
    if (tripsQueryState === "idle") {
      SummarizeTrips(trips);
    }
  }, [tripsQueryState]);

  useEffectAsync(async () => {
    if (stopsQueryState === "idle") {
      SummarizeStops(stops);
    }
  }, [stopsQueryState]);

  const [index, setIndex] = React.useState(0);

  const handleChange = (event, newValue) => {
    setIndex(newValue);
  };

  return (
    <div className="deviceData">
      <Paper sx={{ width: "100%", p: "15px" }}>
        <Box sx={{ display: { xs: "block", sm: "none" } }}>
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale="en"
            localeText={{ start: "Start Date", end: "End Date" }}
          >
            <MobileDateRangePicker
              disableMaskedInput
              value={reportDate}
              inputFormat="Do MMM YYYY"
              onChange={(newValue) => {
                setReportDate(newValue);
              }}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField
                    {...startProps}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField
                    {...endProps}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <Button
                    variant="contained"
                    sx={{
                      ml: 3,
                      color: "white",
                      whiteSpace: "nowrap",
                      minWidth: "max-content",
                    }}
                    onClick={handleGetData}
                  >
                    Get Data
                  </Button>
                </React.Fragment>
              )}
            />
          </LocalizationProvider>
        </Box>
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            localeText={{ start: "Start Date", end: "End Date" }}
          >
            <DesktopDateRangePicker
              inputFormat="Do MMM YYYY"
              disableMaskedInput
              value={reportDate}
              onChange={(newValue) => {
                setReportDate(newValue);
              }}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField {...endProps} />
                  <Button
                    variant="contained"
                    sx={{
                      ml: 3,
                      color: "white",
                      backgroundColor: theme.palette.primary.main,
                      whiteSpace: "nowrap",
                      minWidth: "max-content",
                    }}
                    onClick={handleGetData}
                  >
                    Get Data
                  </Button>
                </React.Fragment>
              )}
            />
          </LocalizationProvider>
        </Box>
      </Paper>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={index}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Device Details" {...a11yProps(0)} />
          <Tab label="Utilization" {...a11yProps(1)} />
          <Tab label="Battery" {...a11yProps(2)} />
          <Tab label="Journey History" {...a11yProps(3)} />
          <Tab label="Stops History" {...a11yProps(4)} />
          <Tab label="All Data" {...a11yProps(5)} />
        </Tabs>
      </Box>
      <div className="tabContent">
        <TabPanel value={index} index={0} className="tabpanel">
          <DeviceDetails selectedId={selectedId} />
        </TabPanel>
        <TabPanel value={index} index={1} className="tabpanel">
          {positionsQueryState === "idle" ? (
            <Utilization
              reportDate={reportDate}
              SummarizedPositions={SummarizedPower}
            />
          ) : (
            <CircularProgress className="spinner" />
          )}
        </TabPanel>
        <TabPanel value={index} index={2} className="tabpanel">
          {positionsQueryState === "idle" ? (
            <BatteryLevel
              reportDate={reportDate}
              SummarizedPositions={SummarizedPower}
            />
          ) : (
            <CircularProgress className="spinner" />
          )}
        </TabPanel>
        <TabPanel value={index} index={3} className="tabpanel">
          {tripsQueryState === "idle" ? (
            <JourneyHistory
              reportDate={reportDate}
              id={selectedId}
              SummarizedTrips={SummarizedTrips}
            />
          ) : (
            <CircularProgress className="spinner" />
          )}
        </TabPanel>
        <TabPanel value={index} index={4} className="tabpanel">
          {stopsQueryState === "idle" ? (
            <StopsHistory
              reportDate={reportDate}
              id={selectedId}
              SummarizedStops={SummarizedStops}
            />
          ) : (
            <CircularProgress className="spinner" />
          )}
        </TabPanel>
        <TabPanel value={index} index={5} className="tabpanel">
          {positionsQueryState === "idle" ? (
            <AllData
              reportDate={reportDate}
              SummarizedPositions={SummarizedPower}
            />
          ) : (
            <CircularProgress className="spinner" />
          )}
        </TabPanel>
      </div>
    </div>
  );
};

const areEqual = (prevProps, nextProps) => {
  if (prevProps.selectedId !== nextProps.selectedId) {
    return false;
  }
  return true; // donot re-render
};

export default React.memo(DeviceData, areEqual);
