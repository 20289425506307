import { createSlice } from "@reduxjs/toolkit";
import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";

const initialState = {
  items: [],
  status: "idle",
};

export const positionsSlice = createSlice({
  name: "positionsList",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    updatePositions(state, action) {
      action.payload.forEach((item) => {
        //remove malformed lat/lon
        if (item.latitude > 90 || item.latitude < -90) {
          item.latitude = 0;
        }
        if (item.longitude > 180 || item.longitude < -180) {
          item.longitude = 0;
        }

        let mergedObject;

        const clonedObject = cloneDeep(state.items[item.deviceId]);

        if (item?.attributes.battery === undefined) {
          mergedObject = merge({}, clonedObject, item);
        } else {
          mergedObject = cloneDeep(item);
        }

        state.items[item.deviceId] = mergedObject;
      });
    },
  },
});

export const { updatePositions } = positionsSlice.actions;

export const selectPosition = (id) => (state) => state.positionsList.items[id];

export const selectPositions = (ids) => (state) => {
  if (!ids || ids.length < 1) {
    return null;
  }

  if (state.positionsList.status === "idle") {
    const positions = [];

    ids.forEach((id, index) => {
      positions.push(state.positionsList.items[id]);
    });
    return positions;
  }
};
export default positionsSlice.reducer;
