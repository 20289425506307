import React from "react";
import {
  Avatar,
  Grid,
  Paper,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Link,
  Typography,
} from "@mui/material";

import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import styles from "./Login.module.css";
import { useFormik } from "formik";
import * as yup from "yup";

import { useDispatch } from "react-redux";
import { loginAsync, getTokenAsync, setToken } from "./loginSlice";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string("Enter your password").required("Password is required"),
});

const Login = () => {
  const dispatch = useDispatch();

  const handleSubmit = async (credValues, actions) => {
    try {
      const response = await dispatch(loginAsync(credValues)).unwrap();

      if (response.status === 200) {
        if (response.data.token) {
          dispatch(setToken(response.data.token));
          if (credValues.rememberme) {
            localStorage.setItem("authtoken", response.data.token);
          }
        } else {
          const token = await dispatch(getTokenAsync()).unwrap();
          dispatch(setToken(token.data));
          if (credValues.rememberme) {
            localStorage.setItem("authtoken", token.data);
          }
        }
      }
    } catch (err) {
      actions.setErrors({
        email: err.message,
        password: "",
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberme: false,
    },

    validateOnChange: true,
    validateOnBlur: true,
    // validateOnMount: true,
    // initialTouched: { email: true },
    // initialErrors: { email: "Required" },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid>
        <Paper elevation={10} className={styles.paperStyle}>
          <Grid align="center">
            <Avatar sx={{ backgroundColor: "primary.dark" }}>
              <LockOpenOutlinedIcon />
            </Avatar>
            <Typography variant="h3">Log In</Typography>
          </Grid>
          <TextField
            fullWidth
            name="email"
            label={"Email"}
            variant="standard"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />

          <TextField
            fullWidth
            name="password"
            label={"Password"}
            variant="standard"
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />

          <FormControlLabel
            className={styles.formcControlLabelStyle}
            control={
              <Checkbox
                name="rememberme"
                color="primary"
                value={formik.values.rememberme}
                onChange={formik.handleChange}
              />
            }
            label="Remember Me"
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={styles.buttonStyle}
            fullWidth
            disabled={!formik.isValid}
          >
            Log In
          </Button>
          <Typography>
            <Link className={styles.signUpLinkStyle} href="#">
              Forgot Password?
            </Link>
          </Typography>
        </Paper>
      </Grid>
    </form>
  );
};

export default Login;
