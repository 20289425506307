import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  deviceDialogOpen: false,
  confirmationDialogOpen: false,
};

export const dialogStateSlice = createSlice({
  name: "dialogState",
  initialState,
  reducers: {
    setDeviceDialogOpen(state, action) {
      state.deviceDialogOpen = action.payload;
    },
    setConfirmationDialogOpen(state, action) {
      state.confirmationDialogOpen = action.payload;
    },
  },
});

export const { setDeviceDialogOpen, setConfirmationDialogOpen } =
  dialogStateSlice.actions;

export const selectDeviceDialogOpen = (state) =>
  state.dialogState.deviceDialogOpen;

export const selectConfirmationDialogOpen = (state) =>
  state.dialogState.confirmationDialogOpen;

export default dialogStateSlice.reducer;
