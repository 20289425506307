import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectSelectedDevice,
  selectSelectedDeviceGroup,
} from "../deviceList/deviceListSlice";
import ShowAddress from "../geocoding/showAddress";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { AutoSizer } from "@enykeev/react-virtualized";
import DeviceLogo from "../shared/DeviceLogo";
import * as yup from "yup";
import { Grid, TextField, Button, Box, Typography } from "@mui/material";
import styles from "./DeviceAttributes.module.css";
import Moment from "moment";
import { selectPosition } from "../deviceList/positionsSlice";
import { selectViewType } from "../map/mapStateSlice";
import { ExportExcel } from "../common/excelExport";
import moment from "moment";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const columns = [
  {
    id: "attribute",
    label: "Attribute",
    minWidth: 110,
    align: "left",
  },
  {
    id: "value",
    label: "Value",
    minWidth: 200,
    align: "left",
  },
];

function createData(attribute, value) {
  if (value === true) {
    value = "Yes";
  }
  if (value === false) {
    value = "No";
  }

  if (attribute === "gpsStatus" || attribute === "io69") {
    let gpsTextStatus = "";
    // 0 - GPS OFF
    // 1 – GPS ON with fix
    // 2 - GPS ON without fix
    // 3 - GPS sleep
    switch (value) {
      case 0:
        gpsTextStatus = "GPS OFF";
        break;
      case 1:
        gpsTextStatus = "GPS ON with fix";
        break;
      case 2:
        gpsTextStatus = "GPS ON without fix";
        break;
      case 3:
        gpsTextStatus = "GPS sleep";
        break;
      default:
        gpsTextStatus = "NO INFO";
    }
    value = gpsTextStatus;
  }

  if (attribute === "io80") {
    let dataMode = "";
    // 0 - GPS OFF
    // 1 – GPS ON with fix
    // 2 - GPS ON without fix
    // 3 - GPS sleep
    switch (value) {
      case 0:
        dataMode = "Home On Stop";
        break;
      case 1:
        dataMode = "Home On Moving";
        break;
      case 2:
        dataMode = "Roaming On Stop";
        break;
      case 3:
        dataMode = "Roaming On Moving";
        break;
      case 4:
        dataMode = "Unknown On Stop";
        break;
      case 5:
        dataMode = "Unknown On Moving";
        break;
      default:
        dataMode = "NO INFO";
    }
    value = dataMode;
  }

  attribute = attribute
    .replace("io205", "GSM Cell ID")
    .replace("io11", "Value of SIM ICCID, MSB, ICCID1")
    .replace("io14", "Value of SIM ICCID, MSB, ICCID2")
    .replace("io24", "km/h")
    .replace("io399", "Time To First Fix")
    .replace("io286", "GSM Signal of the Cell 0")
    .replace("io289", "GSM Signal of the Cell 1")
    .replace("io292", "GSM Signal of the Cell 2")
    .replace("io295", "GSM Signal of the Cell 3")
    .replace("io298", "GSM Signal of the Cell 4")
    .replace("io287", "Location Area Code of the Cell 1")
    .replace("io288", "Unique ID of the Cell 1")
    .replace("io290", "Location Area Code of the Cell 2")
    .replace("io291", "Unique ID of the Cell 2")
    .replace("io293", "Location Area Code of the Cell 3")
    .replace("io294", "Unique ID of the Cell 3")
    .replace("io296", "Location Area Code of the Cell 4")
    .replace("io297", "Unique ID of the Cell 4")
    .replace("io1200", "Mobile Network Code of the Cell 1")
    .replace("io1201", "Mobile Network Code of the Cell 2")
    .replace("io1202", "Mobile Network Code of the Cell 3")
    .replace("io1203", "Mobile Network Code of the Cell 4")
    .replace("io636", "LTE Cell ID")
    .replace("io636", "LTE Cell ID")
    .replace("io20015", "Modem Uptime")
    .replace("priority", "Priority")
    .replace("tripOdometer", "Trip Odometer")
    .replace("hours", "Trip Odometer")
    .replace("odometer", "Odometer")
    .replace("sat", "Satalites")
    .replace("event", "Event")
    .replace("motion", "In Motion")
    .replace("rssi", "Received Signal Strength Indicator")
    .replace("workMode", "Work Mode")
    .replace("gpsStatus", "GPS Status")
    .replace("pdop", "Position Dilution of Precision")
    .replace("hdop", "Horizontal Dilution of Precision")
    .replace("battery", "Tracker Battery Voltage")
    .replace("operator", "Operator")
    .replace("power", "Vehicle Battery Voltage")
    .replace("ignition", "Ignition On")
    .replace("distance", "Distance")
    .replace("out1", "Digital Relay 1 Enabled")
    .replace("totalDistance", "Total Distance")
    .replace("accuracy", "Accuracy")
    .replace("course", "Course")
    .replace("speed", "Speed")
    .replace("altitude", "Altitude")
    .replace("longitude", "Longitude")
    .replace("latitude", "Latitude")
    .replace("valid", "Valid")
    .replace("outdated", "Outdated")
    .replace("fixTime", "Fix Time")
    .replace("deviceTime", "Device Time")
    .replace("serverTime", "Server Time")
    .replace("deviceId", "Device Id")
    .replace("protocol", "Protocol")
    .replace("io80", "Data Mode")
    .replace("io69", "GNSS Status")
    .replace("address", "Address")
    .replace("radioType", "Radio Type")
    .replace("considerIp", "Consider IP")
    .replace("approximate", "Approximate")
    .replace("type", "Type");

  //value = value.toString();

  return {
    attribute,
    value,
  };
}

const DeviceAttributes = (props) => {
  const { selectedId } = props;
  const device = useSelector(selectSelectedDevice(selectedId));
  const position = useSelector(selectPosition(selectedId));
  const group = useSelector(selectSelectedDeviceGroup(device?.groupId));

  const [rows, setRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [cDevice, setCdevice] = useState(-1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  function flattenObject(obj) {
    const result = [];
    function flattenHelper(obj, prefix = "") {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (typeof obj[key] === "object" && obj[key] !== null) {
            flattenHelper(obj[key], prefix + key + ".");
          } else {
            result.push({ key: prefix + key, value: obj[key] });
          }
        }
      }
    }
    flattenHelper(obj);
    return result;
  }

  useEffect(() => {
    rows.length = 0;

    if (position) {
      flattenObject(position)
        .sort((a, b) => {
          const countA = a.key.split(".").length - 1;
          const countB = b.key.split(".").length - 1;

          if (countA > countB) {
            return 1;
          } else if (countA < countB) {
            return -1;
          }
        })
        .map((item) => {
          if (
            item.key === "serverTime" ||
            item.key === "deviceTime" ||
            item.key === "fixTime"
          ) {
            item.value = moment(item.value).format("Do MMMM YYYY, h:mm:ss a");
          }
          rows.push(createData(item.key, item.value));
        });
    }
  }, [position]);

  useEffect(() => {
    if (device) {
      if (cDevice !== device.id) {
        setCdevice(device.id);
        setPage(0);
      }
    }
  }, [device]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const exportExcel = async () => {
    ExportExcel(rows, "Attributes", "Attributes");
  };

  return (
    <AutoSizer>
      {({ height, width }) => (
        <Paper
          sx={{
            width: { width },
            height: { height },
          }}
          className={styles.container}
        >
          <div className={styles.header}>
            {" "}
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 12, sm: 12, md: 12 }}
            >
              <Grid item>
                <Box className={styles.deviceLogo}>
                  <DeviceLogo status={device ? device.status : ""} />
                </Box>
              </Grid>
              <Grid item>
                <Typography variant="h5">
                  <label className={styles.heading}>
                    {device ? device.name : ""}
                  </label>
                </Typography>
                <Typography variant="h5">
                  <label>{group?.name ? group.name : ""} </label>
                </Typography>
                <Typography variant="h5">
                  <label>
                    {device
                      ? Moment(device.lastUpdate).format("DD/MM/yyyy HH:mm")
                      : ""}{" "}
                  </label>
                  <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                  <label className={styles.battery}>
                    {device?.attributes?.ISWIRED === "1"
                      ? "Wired " +
                        (position?.attributes.battery
                          ? position?.attributes?.battery?.toFixed(2) + "V"
                          : "")
                      : "Battery " +
                        (position?.attributes.battery
                          ? position?.attributes?.battery?.toFixed(2) + "V"
                          : "")}
                  </label>
                </Typography>
                <Typography variant="h5">
                  <ShowAddress
                    latitude={position?.latitude}
                    longitude={position?.longitude}
                    deviceId={selectedId}
                  />
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div className={styles.body}>
            <AutoSizer>
              {({ height, width }) => (
                <Paper
                  sx={{
                    width: { width },
                    height: { height },
                  }}
                >
                  <TableContainer
                    sx={{
                      height: "90%",
                      width: "100%",
                    }}
                  >
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow key={"header"}>
                          {columns.map((column) => (
                            <StyledTableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </StyledTableCell>
                          ))}
                          <StyledTableCell
                            key="ExportButton"
                            style={{ width: 70 }}
                          >
                            <Button
                              onClick={() => exportExcel()}
                              variant="contained"
                              color="success"
                              size="small"
                              sx={{
                                width: 100,
                                height: 23,
                                ml: 3,
                                color: "white",
                                whiteSpace: "nowrap",
                                minWidth: "max-content",
                              }}
                            >
                              Export
                            </Button>
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row, index) => {
                            return (
                              <TableRow
                                key={index}
                                hover
                                role="checkbox"
                                tabIndex={-1}
                              >
                                {columns.map((column, index) => {
                                  const value = row[column.id];
                                  return (
                                    <TableCell key={index} align={column.align}>
                                      <label> {value}</label>
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    component={"div"}
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={3}
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </Paper>
              )}
            </AutoSizer>
          </div>
        </Paper>
      )}
    </AutoSizer>
  );
};

export default DeviceAttributes;
