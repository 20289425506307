import React, { useState, useEffect, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import DeviceSearch from "./deviceSearch";
import { AutoSizer } from "@enykeev/react-virtualized";
import ListComponent from "./ListComponent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import {
  setDeviceDialogOpen,
  setConfirmationDialogOpen,
} from "../../features/dialogs/dialogStateSlice";

import {
  setDetailsDrawOpen,
  selectDetailsDrawOpen,
} from "../../features/map/mapStateSlice";

import {
  setSelectedId,
  selectSelectedId,
  selectSelectedIds,
  selectDevices,
  selectFilter,
  selectDeviceGroups,
} from "./deviceListSlice";
import { selectCurrentUser } from "../../features/login/loginSlice";

import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import EditLocationIcon from "@mui/icons-material/EditLocation";
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
import WrongLocationIcon from "@mui/icons-material/WrongLocation";
import LocationOffIcon from "@mui/icons-material/LocationOff";
import NearMeDisabledIcon from "@mui/icons-material/NearMeDisabled";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import TouchAppTwoToneIcon from "@mui/icons-material/TouchAppTwoTone";
import NearMeDisabledTwoToneIcon from "@mui/icons-material/NearMeDisabledTwoTone";
import EditLocationAltTwoToneIcon from "@mui/icons-material/EditLocationAltTwoTone";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";

import { ContactlessOutlined } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";

const DeviceList = (props) => {
  const { appBarHeight, controlHeight, onClose } = props;

  const dispatch = useDispatch();

  const [canEdit, setCanEdit] = useState(false);

  const currentUser = useSelector(selectCurrentUser);

  const selectedId = useSelector(selectSelectedId);
  const selectedIds = useSelector(selectSelectedIds);

  const detailsDrawOpen = useSelector(selectDetailsDrawOpen);

  const devices = useSelector(selectDevices);
  const groups = useSelector(selectDeviceGroups);

  const filterString = useSelector(selectFilter);
  const [sortedItems, setsortedItems] = useState(null);

  const handleDeviceDialogOpen = () => {
    dispatch(setDeviceDialogOpen(true));
  };

  const handleOpenSideBar = () => {
    dispatch(setDetailsDrawOpen(!detailsDrawOpen));
  };

  const handleDeleteConfirmationDialogOpen = () => {
    dispatch(setConfirmationDialogOpen(true));
  };

  useEffect(() => {
    setsortedItems(
      devices
        .map((obj) => ({
          ...obj,
          groupName: groups[obj?.groupId]?.name ? groups[obj.groupId].name : "",
        }))
        .filter(
          (d) =>
            d.name.toLowerCase().includes(filterString.toLowerCase()) |
            d.groupName.toLowerCase().includes(filterString.toLowerCase())
        )
      // .sort((a, b) => {
      //   return (
      //     Number(new Date(b.lastUpdate)) - Number(new Date(a.lastUpdate))
      //   );
      // })
    );
  }, [devices, filterString]);

  useEffect(() => {
    if (
      selectedIds.length !== 1 ||
      currentUser?.data.readonly ||
      currentUser?.data.deviceReadonly
    ) {
      setCanEdit(false);
    } else {
      setCanEdit(true);
    }
  }, [currentUser, selectedIds]);

  //console.log(sortedItems);

  return (
    <Box className="DeviceListContainer">
      <Box
        sx={{ height: `${appBarHeight}px`, backgroundColor: "primary.dark" }}
      >
        <DeviceSearch />
      </Box>
      <Box className="Devicelist" sx={{ height: `${controlHeight}px` }}>
        <AutoSizer>
          {({ height, width }) => (
            <ListComponent
              devices={sortedItems}
              controlHeight={height}
              drawerWidth={width}
              dispatch={dispatch}
              onClose={onClose}
            />
          )}
        </AutoSizer>
      </Box>
      <Box
        sx={{
          height: `${appBarHeight}px`,
          backgroundColor: "primary.dark",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Tooltip title="Edit Item">
              <span>
                <IconButton
                  onClick={handleDeviceDialogOpen}
                  disabled={!canEdit}
                  sx={{
                    ":hover": {
                      bgcolor: "primary.light", // theme.palette.primary.main
                      color: "primary.dark",
                    },
                  }}
                  aria-label="open drawer"
                  edge="end"
                >
                  <EditLocationAltTwoToneIcon
                    style={{
                      opacity: canEdit ? 1 : 0.5,
                      color: "#fff",
                    }}
                    sx={{ fontSize: 40, pr: 0, mr: 0 }}
                  />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <Tooltip title="Remove Item">
              <span>
                <IconButton
                  onClick={handleDeleteConfirmationDialogOpen}
                  disabled={!canEdit}
                  sx={{
                    ":hover": {
                      bgcolor: "primary.light", // theme.palette.primary.main
                      color: "primary.dark",
                    },
                  }}
                  aria-label="open drawer"
                  edge="end"
                >
                  <NearMeDisabledTwoToneIcon
                    sx={{ fontSize: 40, pr: 0, mr: 0 }}
                    style={{
                      opacity: canEdit ? 1 : 0.5,
                      color: "#fff",
                    }}
                  />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <Tooltip title="You have a chance to suggest a use for this button.">
              <span>
                <IconButton
                  disabled={!canEdit}
                  sx={{
                    ":hover": {
                      bgcolor: "primary.light", // theme.palette.primary.main
                      color: "primary.dark",
                    },
                  }}
                  aria-label="open drawer"
                  edge="end"
                >
                  <TouchAppTwoToneIcon
                    sx={{ fontSize: 40, pr: 0, mr: 0 }}
                    style={{
                      opacity: canEdit ? 1 : 0.5,
                      color: "#fff",
                    }}
                  />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <Tooltip title="Item Properties">
              <span>
                <IconButton
                  disabled={selectedIds.length !== 1}
                  onClick={handleOpenSideBar}
                  sx={{
                    ":hover": {
                      bgcolor: "primary.light", // theme.palette.primary.main
                      color: "primary.dark",
                    },
                  }}
                  aria-label="open drawer"
                  edge="end"
                >
                  <MenuTwoToneIcon
                    sx={{ fontSize: 40, pr: 0, mr: 0 }}
                    style={{
                      opacity: selectedIds.length === 1 ? 1 : 0.5,
                      color: "#fff",
                    }}
                  />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const areEqual = (prevProps, nextProps) => {
  const { children: prevChildren, ...restOfPrevProps } = prevProps;
  const { children: nextChildren, ...restOfNextProps } = nextProps;

  if (prevProps !== nextProps) {
    return false;
  }

  return true; // donot re-render
};

export default memo(DeviceList, areEqual);
