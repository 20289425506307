const init = () => {};

const log = (ex) => {
  // console.log(ex);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  init,
  log,
};
