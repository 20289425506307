import http from "../../services/httpService";
import config from "../../config.json";

export async function getDevices(userId) {
  const response = await http.get(config.apiUrl + `/devices`);
  return response;
}

export async function getGroups(userId) {
  const response = await http.get(config.apiUrl + `/groups`);
  return response;
}
