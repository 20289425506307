import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "@mui/material/Button";

import Typography from "@mui/material/Typography";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { logoutAsync, selectToken } from "../login/loginSlice";

const LogOutButton = () => {
  const dispatch = useDispatch();

  const usertoken = useSelector(selectToken);

  // const handleLogout = async (event, token) => {
  //   try {
  //     alert(token);
  //     await dispatch(logoutAsync(token)).unwrap();
  //   } catch (err) {}
  // };

  const handleLogout = async () => {
    try {
      await dispatch(logoutAsync(usertoken)).unwrap();
    } catch (err) {}
  };

  return (
    <Button
      variant="text"
      sx={{
        ":hover": {
          bgcolor: "primary.light", // theme.palette.primary.main
          color: "primary.dark",
        },
      }}
      aria-label="open drawer"
      edge="end"
      onClick={(event, usertoken) => {
        handleLogout(usertoken);
      }}
    >
      <Typography fontSize={20} color="White">
        LOGOUT
      </Typography>
    </Button>
  );
};

export default LogOutButton;
