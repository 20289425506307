import React, { memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectDeviceGroups,
  selectSelectedId,
} from "../features/deviceList/deviceListSlice";

import _ from "lodash";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";

import LogOutButton from "../features/deviceList/logOutButton";

import Toolbar from "@mui/material/Toolbar";
import DeviceDialog from "../features/dialogs/DeviceDialog";
import ConfirmationDialog from "../features/dialogs/ConfirmationDialog";

import {
  setDeviceDialogOpen,
  selectDeviceDialogOpen,
  setConfirmationDialogOpen,
  selectConfirmationDialogOpen,
} from "../features/dialogs/dialogStateSlice";

import { selectSelectedDevice } from "../features/deviceList/deviceListSlice";

const BottomAppBar = (props) => {
  const { drawerWidth } = props;

  const dispatch = useDispatch();

  const selectedId = useSelector(selectSelectedId);

  const deviceDialogOpen = useSelector(selectDeviceDialogOpen);
  const confirmationDialogOpen = useSelector(selectConfirmationDialogOpen);

  const selectedDevice = useSelector(selectSelectedDevice(selectedId));

  const groups = useSelector(selectDeviceGroups);

  const handleDeviceDialogClose = () => {
    dispatch(setDeviceDialogOpen(false));
  };
  const handleConfirmationDialogClose = () => {
    dispatch(setConfirmationDialogOpen(false));
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        bgcolor: "primary.dark",
        top: "auto",
        bottom: 0,
        width: { md: `calc(100% - ${drawerWidth}px)` },
        ml: { md: `${drawerWidth}px` },
      }}
    >
      <Toolbar sx={{ width: "100%" }}>
        <Box sx={{ marginLeft: "auto", pr: "2px" }}>
          {/* <Button
            variant="text"
            sx={{
              ":hover": {
                bgcolor: "primary.light", // theme.palette.primary.main
                color: "primary.dark",
              },
            }}
            aria-label="open drawer"
            edge="end"
            onClick={handleDummyItemClick}
          >
            <Typography fontSize={20} color="White">
              My Account
            </Typography>
          </Button> */}
          {/* <Button
            variant="text"
            sx={{
              ":hover": {
                bgcolor: "primary.light", // theme.palette.primary.main
                color: "primary.dark",
              },
            }}
            aria-label="open drawer"
            edge="end"
            onClick={handleDummyItemClick}
          >
            <Typography fontSize={20} color="White">
              Users
            </Typography>
          </Button> */}
          {/* <Button
            variant="text"
            sx={{
              ":hover": {
                bgcolor: "primary.light", // theme.palette.primary.main
                color: "primary.dark",
              },
            }}
            aria-label="open drawer"
            edge="end"
            onClick={handleDummyItemClick}
          >
            <Typography fontSize={20} color="White">
              Groups
            </Typography>
          </Button> */}
          {/* <Button
            variant="text"
            sx={{
              ":hover": {
                bgcolor: "primary.light", // theme.palette.primary.main
                color: "primary.dark",
              },
            }}
            aria-label="open drawer"
            edge="end"
            onClick={handleDummyItemClick}
          >
            <Typography fontSize={20} color="White">
              Drivers
            </Typography>
          </Button> */}
          {/* <Button
            variant="text"
            sx={{
              ":hover": {
                bgcolor: "primary.light", // theme.palette.primary.main
                color: "primary.dark",
              },
            }}
            aria-label="open drawer"
            edge="end"
            onClick={handleDummyItemClick}
          >
            <Typography fontSize={20} color="White">
              Geofences
            </Typography>
          </Button> */}
          {/* <Button
            variant="text"
            sx={{
              ":hover": {
                bgcolor: "primary.light", // theme.palette.primary.main
                color: "primary.dark",
              },
            }}
            aria-label="open drawer"
            edge="end"
            onClick={handleDummyItemClick}
          >
            <Typography fontSize={20} color="White">
              System
            </Typography>
          </Button> */}
          <LogOutButton />
        </Box>
      </Toolbar>
      <DeviceDialog
        open={deviceDialogOpen}
        device={selectedDevice}
        groups={groups}
        handleClose={handleDeviceDialogClose}
      />
      <ConfirmationDialog
        open={confirmationDialogOpen}
        device={selectedDevice}
        handleClose={handleConfirmationDialogClose}
      />
    </AppBar>
  );
};

const areEqual = (prevProps, nextProps) => {
  if (_.isEqual(prevProps.device, nextProps.device)) {
    return true;
  }

  return false; // donot re-render
};

export default memo(BottomAppBar, areEqual);
